<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <section class="mb-4">
      <div class="container">
        <page-loading :show="loading" />
        <div class="wrapper--title">
          <!--          <h4 class="modal-title">{{ $t('modal.signFkp') }}</h4>-->
          <hr />
        </div>
        <div class="wrapper--main">
          <div class="d-flex mb-4">
            <div class="btn btn-primary--outline main--btn" @click="backToDetail">
              {{ $t('general.btn.back') }}
            </div>
          </div>
          <div style="overflow-x: scroll" v-html="digitalSignTemplate"></div>
          <div class="wrapper--footer">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="checkboxagreement"
                name="agreement"
                v-model="agreement"
              />
              <label class="custom-control-label" for="checkboxagreement">
                {{ $t('booking.messageAgreement') }}</label
              >
            </div>
            <div class="d-flex mt-2">
              <div class="btn btn-primary--outline main--btn mr-2" @click="backToDetail">
                {{ $t('general.btn.back') }}
              </div>
              <button
                @click="openCancelBookModal"
                class="btn btn-danger color-white mx-1 mr-2"
                type="button"
                :disabled="agreement"
              >
                {{ $t('booking.notAgree') }}
              </button>
              <button class="btn btn-primary mr-2" @click="sign" :disabled="!agreement">
                {{
                  $route.query.action_name === 'OWNER_SIGN_OTP'
                    ? $t('booking.acceptSign')
                    : $t('booking.sign')
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <reject-sell-modal @actionDone="backToDetail" />
    </section>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import { mapState } from 'vuex';
const PageLoading = () => import('@/components/content-loading/page-loading');

const RejectSellModal = () =>
  import('@/components/application-action/modals/sell-transaction/reject-modal');
export default {
  name: 'booking-sign',
  components: {
    DefaultLayout,
    PageLoading,
    RejectSellModal,
  },
  async fetch({ store, params, router, query, route }) {
    store.commit('head/RESTORE_DEFAULT_VALUE', route);
    const uuid = params.uuid;
    store.commit('sellTransaction/SET_SELL_TRANSACTION_UUID', uuid);
    try {
      const { data } = await store.dispatch('sellTransaction/checkSignFkp', uuid);
      if (!data) router.replace('/');
    } catch (_) {
      router.replace('/');
    }
    await store.dispatch('sellTransaction/getFkpTemplate', {
      ownerName: query.owner_name,
      ownerKtp: query.owner_ktp,
      agentName: query.agent_name,
      agentCeaNumber: query.agent_cea_number,
      lawPracticeName: query.law_practice_name,
      bankName: query.bank_name,
      bankAccountNumber: query.bank_account_number,
      lawFirmName: query.law_firm_name,
      solicitorName: query.solicitor_name,
    });
    store.commit('sellTransaction/acceptRequest/SET_UUID', uuid);
    if (query.owner_name)
      store.commit('sellTransaction/acceptRequest/SET_OWNER_NAME', query.owner_name);
    if (query.owner_ktp)
      store.commit('sellTransaction/acceptRequest/SET_OWNER_KTP', query.owner_ktp);
    if (query.agent_name)
      store.commit('sellTransaction/acceptRequest/SET_AGENT_NAME', query.agent_name);
    if (query.agent_cea_number)
      store.commit('sellTransaction/acceptRequest/SET_AGENT_CEA_NUMBER', query.agent_cea_number);
    if (query.law_practice_name)
      store.commit('sellTransaction/acceptRequest/SET_LAW_PRACTICE_NAME', query.law_practice_name);
    if (query.bank_name)
      store.commit('sellTransaction/acceptRequest/SET_BANK_NAME', query.bank_name);
    if (query.bank_account_number)
      store.commit(
        'sellTransaction/acceptRequest/SET_BANK_ACCOUNT_NUMBER',
        query.bank_account_number,
      );
    if (query.law_firm_name)
      store.commit('sellTransaction/acceptRequest/SET_LAW_FIRM_NAME', query.law_firm_name);
    if (query.solicitor_name)
      store.commit('sellTransaction/acceptRequest/SET_SOLICITOR_NAME', query.solicitor_name);
  },
  data() {
    return {
      agreement: false,
    };
  },
  computed: {
    ...mapState({
      bookingInfo: state => state.v2.booking.detail.bookingInfo,
      digitalSignTemplate: state => state.sellTransaction.fkpTemplate,
      loading: state => state.sellTransaction.fkpSignLoading,
    }),
    breadcrumbs() {
      if (!this.bookingInfo) return [];
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('dashboard.myBooking'),
          exact: true,
          to: '/dashboard/mybooking',
        },
        {
          text: `${this.$t('booking.number')} ${
            this.bookingInfo && this.bookingInfo.order_number
              ? this.bookingInfo.order_number
              : this.$route.params.uuid
          }`,
          to: `/mybooking/detail?type=SELL_TRANSACTION&uuid=${this.$route.params.uuid}`,
        },
        {
          text: this.$t('breadcrumb.signOtp'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  methods: {
    openCancelBookModal() {
      this.$modal.show('sell-reject-modal', {
        uuid: this.$route.params.uuid,
        buttonName: this.$i18n.t('booking.cancelReservation'),
        actionName:
          this.$route.query.action_name === 'OWNER_SIGN_OTP'
            ? 'CANCEL_OWNER_ACCEPT'
            : 'CANCEL_APPLICANT_SIGN',
      });
    },
    async sign() {
      if (this.$route.query.action_name === 'OWNER_SIGN_OTP') {
        this.$swal({
          title: this.$i18n.t('booking.swal.acceptSell.title'),
          text: this.$i18n.t('booking.swal.acceptSell.message'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('general.yes'),
          cancelButtonText: this.$i18n.t('general.no'),
          allowOutsideClick: false,
        }).then(async result => {
          if (result.value) {
            try {
              this.$store.commit('sellTransaction/SET_FKP_SIGN_LOADING', true);
              // const postAcceptResult = await this.$store.dispatch('sellTransaction/ownerAccept', {
              //   uuid: this.$route.params.uuid,
              //   finalPrice: this.$route.query.final_price,
              // });
              const postAcceptResult = await this.$store.dispatch(
                'sellTransaction/acceptRequest/postAcceptBooking',
              );
              console.log('Post Accept Result: ', postAcceptResult);
              await this.$swal(
                this.$t('general.success'),
                this.$t('booking.swal.acceptSell.success'),
                'success',
              );
              this.backToDetail();
            } catch (e) {
              console.log('Error accepting sell booking: ', e);
            } finally {
              this.$store.commit('sellTransaction/SET_FKP_SIGN_LOADING', false);
            }
          }
        });
      } else {
        this.$swal({
          title: this.$i18n.t('booking.swal.signOtp.title'),
          text: this.$i18n.t('booking.swal.signOtp.message'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('general.yes'),
          cancelButtonText: this.$i18n.t('general.no'),
          allowOutsideClick: false,
        }).then(async result => {
          if (result.value) {
            await this.$store.dispatch('sellTransaction/signFkp');
            await this.$swal(
              this.$t('general.success'),
              this.$t('booking.swal.signOtp.success'),
              'success',
            );
            this.backToDetail();
          }
        });
      }
    },
    backToDetail() {
      this.$router.replace(
        `/mybooking/detail?type=SELL_TRANSACTION&uuid=${this.$route.params.uuid}`,
      );
    },
  },
};
</script>

<style scoped></style>
